<template>
  <div class="page singer-page">
    <!-- <SearchBar
      v-if="browserType === 'landscape'"
      :custom-style="{  marginLeft: '50px' }"
      placeholder="搜索歌手、歌曲"
      title="歌手"
      default-tab="singer"
      :isShowSearchBtn="true"
      :isShowRight="false"
    /> -->
    <!-- <template v-else> -->
    <HeadBar
      class="singer-headbar"
      title="歌手"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="true"
    />
    <PureSearch
      class="singer-bar-pure"
      :isSearch="false"
      placeholder="搜索歌手、歌曲"
    />
    <!-- </template> -->

    <div class="singer-content">
      <div class="singer-tab">
        <div
          class="singer-tab-item"
          v-for="(item, index) in tabList"
          :key="item"
          @click="handleChangeTab(item)"
        >
          <span v-if="index != 0"></span>
          <div
            class="singer-tab-item-txt"
            :class="{ 'singer-tab-item-active': curTab == item }"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <LoadMore
        class="singer-list"
        ref="loadMoreRef"
        v-if="singerUnionList.length"
        @load-more="fetchSingerList"
        safeAreaHeight="14.6991vw"
      >
        <SingerItem
          v-for="(item, index) in singerUnionList"
          :singer="item"
          :key="index"
          @click="handleClickSinger(item)"
        ></SingerItem>
      </LoadMore>
      <p class="no-data" v-if="singerUnionList.length <= 0">
        抱歉，暂无“{{ curTab }}”的结果
      </p>
      <p class="hint" v-if="isEmpty && singerUnionList.length > 20">
        已加载全部
      </p>
    </div>

    <LbBanner />

    <!-- 功能下线 -->
    <!-- <RecommendSong :isActive="isShowRecommend" @close="handleCloseRecommend" /> -->
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted, onActivated } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useShareBrowserSize } from "@/composables/sharedComposable";
import useRecommend from "@/composables/useRecommend";

import { getSingerClassList, getSingerList } from "@/service/singer";

import SingerItem from "@/components/singer-item/index.vue";
import LbBanner from "@/components/lb-banner/index.vue";
import PureSearch from "../search/components/search-bar/index.vue";
// import RecommendSong from "./components/recommend-song/index.vue";
import { sendLog } from "@/directives/v-log/log";
import {
  setCloseSingerRecommendTag,
  getCloseSingerRecommendTag,
  getReduceSingerRecommendSetting,
} from "@/utils/historyCache";
import logParams from "@/log";

export default {
  name: "SingerList",
  components: {
    SingerItem,
    LbBanner,
    PureSearch,
    // RecommendSong,
  },
  activated() {
    const store = useStore();
    const { isUsed, position } = store.state.pageCacheData.singer;
    if (!isUsed) {
      this.handleInitData();
    } else {
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position;
      }
      this.handleUpdateCachePosition(false, 0);
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { browserType } = useShareBrowserSize();
    const { showRecommendConfirm } = useRecommend();

    const isShowRecommend = ref(false);

    const tabList = ref([]);
    let loadMoreRef = ref(null);
    let singerList = ref([]);
    let curTab = ref("");
    let p = 1;
    let version = {
      current: "",
      latest: "",
    };
    let isEmpty = ref(false);
    let isRequest = false;
    let timer = null;

    const tabLogMap = {
      飙升周榜: 10037,
      热门歌星: 10038,
      大陆男歌星: 10039,
      中国组合: 10040,
      大陆女歌星: 10041,
      港台女歌星: 10042,
      港台男歌星: 10043,
      外国歌星: 10044,
    };

    // 歌手列表去重
    const singerUnionList = computed(() => {
      const idsMap = new Map();
      for (let i in singerList.value) {
        if (!idsMap.has(singerList.value[i].singerid)) {
          idsMap.set(singerList.value[i].singerid, singerList.value[i]);
        }
      }
      return Array.from(idsMap.values());
    });

    const fetchSingerClassList = async () => {
      tabList.value = await getSingerClassList();
      handleInitTab(tabList.value[0]);
    };

    const fetchSingerList = async () => {
      if (isRequest) {
        return;
      }
      isRequest = true;

      let bussinessResponseData = {};
      // if (tab === '飙升周榜') {
      //   bussinessResponseData = await getSingerRiseList({
      //     p,
      //     version: version.latest
      //   })
      // } else {
      bussinessResponseData = await getSingerList({
        p,
        k: curTab.value,
        version: version.latest,
      });
      // }

      if (bussinessResponseData.data.length !== 0) {
        if (p === 1 && bussinessResponseData.version) {
          version = bussinessResponseData.version;
        }
        singerList.value = singerList.value.concat(bussinessResponseData.data);
        p++;
      }
      isRequest = false;
    };

    const handleInitTab = (val) => {
      const params = route.query;
      handleChangeTab(params.tab ? params.tab : val);
    };

    const handleInitData = () => {
      singerList.value = [];
      curTab.value = "";
      p = 1;
      version = {
        current: "",
        latest: "",
      };
      isEmpty.value = false;
      isRequest = false;
      fetchSingerClassList();
    };

    const handleChangeTab = (tab) => {
      curTab.value = tab;
      if (tabLogMap[tab]) {
        sendLog({
          event_type: "10000~50000",
          event_name: tabLogMap[tab],
          event_data: {
            str1: "歌星",
            str2: "类型区",
            str3: tab,
            str4: "click",
          },
        });
      }
    };

    const handleClickSinger = ({ singername, singerheader, singerid }) => {
      sendLog({
        event_type: "10000~50000",
        event_name: 10045,
        event_data: {
          str1: "歌星",
          str2: "歌手区",
          str3: singername,
          str4: "click",
        },
      });
      router.push({
        name: "songList",
        query: {
          name: singername,
          image: singerheader,
          singerid,
        },
      });
    };

    const handleCloseRecommend = (isHasControl) => {
      console.log(isHasControl, 'handleCloseRecommend isHasControl')
      isShowRecommend.value = false
      if (!isHasControl) {
        // open recommend song modal logic
        const closeTag = getCloseSingerRecommendTag();
        if (closeTag > 2) return;
        if (closeTag === 2) {
          //3次无操作
          showRecommendConfirm();
          sendLog(logParams.get(30304));
        }
        setCloseSingerRecommendTag(closeTag + 1);
      }
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit("UPDATE_PAGE_CACHEDATA", {
        data: {
          isUsed: u,
          position: v,
        },
        type: "singer",
      });
    };

    /**
     * 1 - 保持推荐 60s弹出
     * 2 - 减少推荐频率 120s弹出
     * 3 - 从不推荐
     */
    const startRecommendTimer = () => {
      const setting = getReduceSingerRecommendSetting();
      if (setting === 3) return;
      timer = setTimeout(() => {
        isShowRecommend.value = true;
        sendLog(logParams.get(30308))
      }, setting === 2 ? 120000 : 60000);
    };

    const clearRecommendTimer = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };

    watch(curTab, (tab) => {
      if (tab) {
        singerList.value = [];
        p = 1;
        fetchSingerList();
      }
    });

    // watch(isShowRecommend, (val) => {
    //   if (val) {
    //     startRecommendTimer()
    //   } else {
    //     clearRecommendTimer()
    //   }
    // })

    const closeRecommend = () => {
      isShowRecommend.value = false;
    }

    onBeforeRouteLeave((to, from, next) => {
      clearRecommendTimer();
      closeRecommend();
      if (to.name === "search" || to.name === "songList") {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0;
        handleUpdateCachePosition(true, position);
      }
      next();
    });

    // onMounted(() => {
    //   console.log('singer onMounted')
    //   startRecommendTimer();
    // });

    onActivated(() => {
      console.log('singer onActivated')
      startRecommendTimer();
    });

    onUnmounted(() => {
      clearRecommendTimer();
      closeRecommend();
    });

    return {
      tabList,
      loadMoreRef,
      singerList,
      singerUnionList,
      curTab,
      p,
      isEmpty,
      // isShowRecommend,
      handleInitData,
      handleChangeTab,
      handleClickSinger,
      handleUpdateCachePosition,
      fetchSingerList,
      browserType,
      // handleCloseRecommend,
    };
  },
};
</script>

<style lang="stylus" scoped>
.page
  min-height 100vh
  background: #000000B2
  .search-bar
    background none
  .singer-content
    position relative
    display flex
    flex-direction column
    align-items center
    // justify-content center
    width 825px
    height 916px
    position fixed
    right 80px
    // top 164px
    top 292px
    @media screen and (max-width 1200px)
      width 1080px
      height 992px
      position absolute
      top 887px
      left 60px
.singer-page
  // padding-right 184px
  // padding-left 184px
  padding 0 80px
  height 100vh
  overflow hidden
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-right 60px
    padding-left 60px
  .singer-tab
    // width calc(100vw - 364px)
    width 825px
    height 100px
    // position absolute
    // top 35px
    // z-index 9
    display flex
    align-items center
    overflow-x scroll
    // margin-left -26px
    // margin-bottom 60px
    margin-bottom 50px
    @media screen and (max-width 1200px) and (min-height 1200px)
      width calc(100vw - 120px)
      margin 20px 0 60px
    &::-webkit-scrollbar
      display none
    &-item
      width auto
      height 64px
      display flex
      align-items center
      flex-shrink 0
      border-bottom 2px solid rgba(255, 255, 255, 0.10)
      span
        width 2px
        height 32px
        background rgba(255, 255, 255, 0.10)
      &-txt
        color rgba(255, 255, 255, 0.40)
        font-size 32px
        font-weight 400
        padding 0 16px
        width auto
        max-width 400px
        margin 0 26px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        height 64px
        @media screen and (max-width 1200px) and (min-height 1200px)
          font-size 26px
      &-active
        color #DBAE6A
        border-bottom 2px solid #DBAE6A
        font-weight 400
  .singer-list
    display grid
    text-align center
    // grid-template-columns repeat(6, 216px)
    grid-template-columns repeat(4, 174px)
    width 825px
    justify-content space-between
    // padding 0 0 350px 0 !important
    padding 0 0 100px 0 !important
    box-sizing border-box
    ::v-deep .singer-item
      width 174px
      height 218px
      margin-bottom 50px
      .singer-item-cover
        width 174px
        height 174px
        margin-bottom 17px
      p
        width 174px
        line-height 1
    &::-webkit-scrollbar
      display none
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 1080px
      grid-template-columns repeat(4, 200px)
      ::v-deep .singer-item
        width 200px
        height 246px
        margin-bottom 60px
        .singer-item-cover
          width 200px
          height 200px
          margin-bottom 20px
        p
          width 200px
          line-height 1
  .no-data
    font-size 28px
    color rgba(255, 255, 255, 0.5)
    text-align center
    line-height 650px
    @media screen and (max-width: 1200px)
      height 650px
  .hint
    text-align center
    color #555555
  .singer-headbar
    background transparent
  .singer-bar-pure
    background transparent
    position fixed
    top 164px
    left 1014px
    width 826px
    padding 0
    // margin auto
    @media screen and (max-width 1200px)
      top 775px
      left 0
      right 0
      bottom 0
      width 100%
      padding 0 5vw
</style>